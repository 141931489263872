.arrow-content {
	position: absolute;
	left: 50vw;
	top: 90vh;
	transform: translate(-50%, -50%);
	
	p {
		margin: 0 0 20px;
	}
	
}
  
/* Arrow & Hover Animation */
#more-arrows {
	width: 75px;
	height: 65px;

	&:hover {
		polygon {
			fill: #FFF;
			transition: all .5s ease-out;
			&.arrow-top {
				transform: translateY(18px);
			}
		}
	}
}
  
polygon {
	fill: #FFF;
	transition: all .6s ease-out;
	  
	&.arrow-middle {
		opacity: 0.75;
	}

	&.arrow-top {
		opacity: 0.5
	}
}