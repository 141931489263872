body, html {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  height: 100%;
}

.app {
  background-color: #dbdbdb;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.app-opacity {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0.95);
}

.app-opacity img {
  opacity: 0.25;
}

.section-title {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  justify-content: center;
  position: relative;
  padding-top: 2vh;
  color: #0D0802;
  display: inline-block;
}

.section-description {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 300;
  text-align: center;
  justify-content: center;
  padding-bottom: 5vh;
  font-size: 1.3rem;
}

/* Stage design */
.picto-sd {
  position: absolute;
  top: 28px;
  right: -20px;
}

/* Arts numériques */
.picto-an {
  position: absolute;
  top: 28px;
  right: -20px;
}

/* Videos */
.picto-v {
  position: absolute;
  top: 28px;
  right: 5px;
}

/* Conception 3D */
.picto-3d {
  position: absolute;
  top: 28px;
  right: -20px;
}

hr{
  border: none;
  border-top: 2px solid #000000;
  overflow: visible;
  text-align: center;
  width: 40vw;
}
.section-hr{
  width: 200px;
}
/* Home component */
.home {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.home-image {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.home-logo {
  display: block;
  margin: 0px auto;
  position: relative;
}

.home-text {
  text-align: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  padding-top: 25vh;
}
.home-subtitle {
  color: #000000;
  font-size: 1.5rem;
}

/* Header Component */

.headercontainer {
  text-align: center;
}

.header-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
  margin: 1rem 5rem 0rem 5rem;
}

.header-item {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}


.header-item p {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.header-item p::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: center;
  transition: transform 0.25s ease-in-out;
}

.header-item p:hover::after {
  transform: scaleX(1);
  transform-origin: center;
}

.vl {
  border-left: 1px solid black;
  height: 1.5rem;
  margin-top: 1.5rem;
}

/* Description Component */

.description {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 300;
  text-align: center;
  justify-content: center;
  padding-top: 2vh;
  font-size: 1.4rem;
}

/* StageDesign Component */

.stage-design {
  text-align: center;
}

.tour {
  text-align: center;
}

.arts-numeriques {
  text-align: center;
}

.video {
  text-align: center;
}

.conception-3d {
  text-align: center;
}

.container {
  /* max-width: 90vw; */
  margin: 0 auto;
}

.photos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}

.photos .image-div {
  position: relative;
  transition: 0.4s;
  text-align: center;
  padding: 0.5vh;
}

.photos img {
  width: 95%;
  height: 95%;
  object-fit: cover;
  cursor: pointer;
}

.photos .image-div:hover {
  transform: scale(0.98);
  opacity: 0.7;
}

.image-div svg {
  opacity: 0%;
  color: #3f3f3f;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition:  opacity 0.2s ease-in-out;
}
.image-div:hover > svg{
  opacity: 70%;
}

@media screen and (min-width: 769px){
  .photos .image-div {
    width: calc(100vw/3.5);
    height: calc(100vh/3.5);
  }
  .dialog {
    display: flex;
  }
  .home-logo {
    width: 60vw;
    margin-top: -5vh;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .photos .image-div{
    width: calc(100vw/3);
    height: calc(100vh/3);
  }
  .home-logo {
    width: 75vw;
    margin-top: 5vh;
  }
}

@media screen and (min-width: 321px) and (max-width: 480px) {
  .photos .image-div{
    width: calc(100vw/2);
    height: calc(100vh/2);
  }
  .home-logo {
    width: 80vw;
    margin-top: 10vh;
  }
}

@media screen  and (max-width: 320px) {
  .photos .image-div{
    width: 100vw;
    height: 75vh;
  }
  .home-logo {
    width: 85vw;
    margin-top: 10vh;
  }
}

@media screen and (min-width: 769px){
  .dialog {
    display: flex;
    margin: auto;
    justify-content: flex-start;
    width: 85vw;
    left: 45%;
  }
  .dialogConception3d {
    width: 75vw;
  }
  .dialog .title {
    margin-top: 0vh;
  }
  .dialog .text {
    padding-left: 1vw;
  }
  .dialog img {
    width: 85%;
  }
}

@media screen and (max-width: 769px){
  .dialog {
    margin: auto;
    justify-content: flex-start;
    width: 100vw;
    left: 50%;
  }
  .dialogConception3d {
    width: 95vw;
  }
  .dialog .title {
    margin-top: 2vh;
  }
  .dialog .text {
    width: 100%;
  }
  .dialog img {
    max-height: 65vh;
    width: 100%;
  }
}

.dialog {
  position: fixed;
  left: 50%;
  right: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;
  z-index: 100;
  top: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background: none;
  transition: 0.4s;
  padding: 10px;
  box-sizing: border-box;
  resize: horizontal;
  overflow: auto;
  height: calc(100vh - 8px);
}

.dialogConception3d {
  left: 50%;
  position: fixed;
  z-index: 100;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border: none;
  background: none;
  height: 90vh;
  transition: 0.4s;
}
.dialogConception3d img {
  object-fit: cover;
  width: 100%;
  opacity: 1;
}

.dialog .a {
  padding: 3vh;
}

.dialog img {
  object-fit: contain;
  max-height: 75vh;
  width: 85%;
  opacity: 1;
}

.dialog .text {
  font-family: "M PLUS Rounded 1c", sans-serif;
  color: white;
  justify-content: center;
}

.dialog .title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2rem;
  margin: 0;
  margin-top: 4%;
}

.dialog .description {
  justify-content: center;
  font-weight: 100;
  padding-bottom: 1vh;
  font-weight: 300;
}

.dialog .h1 {
  padding-top: 2vh;
  font-size: 0.8rem;
}

.dialog .credit {
  justify-content: center;
  font-weight: 100;
}

/*Video Section*/
#video {
  justify-content: center;
  /* display: flex; */
}
.video-grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: space-around;
}
.player-wrapper {
  /* position: relative; */
}
.react-player {
  padding: 1vh;
}

/* Contact section */

.contact {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 300;
  /* margin-top: 10vh; */
  text-align: center;
  justify-content: center;
  align-items: center;
  background: #5e5e5e;
  color: white;
}

.contact-section {
  margin-left: 5%;
  margin-right: 5%;
  vertical-align: top;
  display: inline-block;
}

.contact-icon {
  padding: 2vw;
}

.copyright {
  font-size: 1rem;
  padding-bottom: 1vh;
}

.mentions-legales-page {
  background-color: rgba(0, 0, 0, 0.95);
  font-family: "M PLUS Rounded 1c", sans-serif;
  color: white;
  padding: 20px;
  /* display: flex;
  margin: auto; */
}

.header {
  text-align: center;
  margin-bottom: 4vh;
}

.content {
  max-width: 800px;
  margin: 0 auto;
}

.logo {
  max-width: 40vw;
  height: auto;
  object-fit: contain;
}

a { text-decoration: none; color: white; font-style: italic;}
a:visited { text-decoration: none; color: white}
a:hover { text-decoration: none; color: white}
a:focus { text-decoration: none; color: white}
a:hover, a:active { text-decoration: none; color: white}

/* Auto adress section */


@keyframes animate-fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.csvExport {
  background-color: #FFFFFF;
  padding: 15px;
  border-radius: 10px;
  font-family: "M PLUS Rounded 1c", sans-serif;
  color: #0D0802;
  font-size: large;
  font-style: normal;
}

.csvExport:hover{
  font-style: italic;
  text-decoration: underline;
  background-color: #EEEEEE;
}

.auto-adress {
  flex-shrink: 0;
  font-family: "M PLUS Rounded 1c", sans-serif;
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(135deg, #DBDBDB 0%, #F5F5F5 100%);
  padding: 20px;
  box-sizing: border-box;
}

.auto-adress * {
  animation-duration: 0.4s;
  animation-name: animate-fade;
  animation-delay: 0s;
  animation-fill-mode: both;
}

.controller-section, .output-section {
  background-color: #FFFFFF;
  padding: 15px;
  margin: 10px 0;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.controller-section:hover, .output-section:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.auto-adress h3 {
  display: flex;
  margin: 10px 0px 40px 0px;
  text-align: center;
  font-size: 24px;
  color: #333;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.auto-adress h4 {
  margin: 10px 0;
  text-align: left;
  font-size: 20px;
  color: #555;
}
.auto-adress h6 {
  color: #555;
  margin-left: 15px;
  margin-right: 15px;
}

.auto-adress label {
  display: block;
  margin: 5px 0;
  font-size: 14px;
  color: #666;
}

.auto-adress input, select {
  margin-left: 10px;
  background-color: #EEEEEE;
  border-radius: 5px;
  border-width: 0px;
  width: 50px;
  padding: 5px;
  transition: all 0.3s ease;
}

.auto-adress input:focus, select:focus {
  background-color: #FFFFFF;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.inputWarning {
  color: red !important;
}

.setup {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  scrollbar-width: thin;
  justify-content: flex-start;
}

.setupHeader {
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.setupHeader h1 {
  font-size: 28px;
  color: #333;
  margin-bottom: 10px;
}

.setupHeader label {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
}

.setupHeader input {
  font-size: 24px;
  margin-bottom: 20px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.addLed {
  background-color: #ABABAB;
  border-radius: 5px;
  padding: 10px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.addLed:hover {
  background-color: #8A8A8A;
  cursor: pointer;
}

.setupbarre1m, .setupbarre2m {
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  width: 75%;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease;
}

.setupbarre1m {
  background-color: #e0eeff;
  border: 2px solid #B0D4FF;
}

.setupbarre2m {
  background-color: #ccceef;
  border: 2px solid #9EA6FF;
}

.setupbarre1m:hover, .setupbarre2m:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.removeBarre:hover {
  cursor: pointer;
  color: #ff5c5c;
}

.ledGroup {
  display: flex;
  align-items: center;
  height: 80px;
}

.ledGroupChild {
  margin: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.renduLedBorder {
  width: 50%;
  /* position: fixed; */
  padding: 10px;
}

.renduLed {
  display: flex;
  overflow: auto;
}

.renduControlleur {
  background-color: #EEEEEE;
  border-radius: 5px;
  padding: 15px;
  margin: 10px;
  display: flex;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.renduSortie {
  display: flex;
  flex-direction: column;
  padding: 2px;
}

.barre1m, .barre2m {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  border-radius: 5px;
  margin: 2px;
  justify-content: center;
}

.barre1m {
  background-color: #e0eeff;
  border: 2px solid #B0D4FF;
  height: 56px;
  width: 15px;
}

.barre2m {
  background-color: #ccceef;
  border: 2px solid #9EA6FF;
  height: 120px;
  width: 15px;
}
