.containerResize {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	max-width: 500px;
	margin: 20px auto;
	background-color: #f9f9f9;
}

.input-containerResize {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 15px;
}

.input-containerResize input[type="file"] {
	margin-bottom: 10px;
}

.input-containerResize input[type="text"] {
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.button-containerResize {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.buttonResize {
	padding: 10px 20px;
	border: none;
	border-radius: 4px;
	background-color: #575757;
	color: white;
	cursor: pointer;
	transition: background-color 0.3s;
}

.buttonResize:hover {
	background-color: #a7a7a7;
}

.containerResize img {
	margin-top: 20px;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.imgInfo {
    margin: 20px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}